import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import Seo from "../components/seo";
import Slider from "../blocks/carousel";
import EnterpriseActivity from "../blocks/enterpriseactivity";
import Largepopup from "../blocks/largepopup";

export const data = graphql`query ResourcesTemplateQuery($ageGroupBadge: String! $ageGroup: String! $ageLang: String!, $resourcesDownload: String!) {
  badge: file(relativePath: {eq: $ageGroupBadge}) {
    publicURL
  }
  guide: file(relativePath: {eq: $resourcesDownload}) {
    publicURL
  }
  enterprise: lessonsJson(ageGroup: {eq: $ageGroup}, resourceType: {eq: "enterprise"}, lang: {eq: $ageLang}) {
    activityNumber
    activityPlan
    ageGroup
    description
    icon
    id
    lang
    level
    name
    resourceType
    resourcesURL
    short
    space
    time
    url
  }
}`

let englishArray = {
    'banner' : {
        'para1': 'View the sessions on this page by duration, or as a connected journey from 1 – 8.',
        'para2': 'Click “View as journey” to see the sessions in the order they appear in the Teacher’s guide.',
        'buttonTop': 'View as journey',
        'buttonBtm': 'Download Teacher\'s guide'
    },
    'shortSessions': {
        'title': 'Short sessions',
        'para': 'These shorter activities are great for introducing concepts to a class in flexible, bite-sized chunks.'
    },
    'fullSessions': {
        'title': 'Full sessions',
        'para': 'These are full activities focusing on different subjects and learning through play.'
    },
    'enterprise': {
        'title': 'Enterprise project',
        'para': 'These mini-projects are group activities that you can complete with your class over a few lessons and could also form part of larger projects, which might span an entire term.'
    }
};

let welshArray = {
    'banner' : {
        'para1': 'Gweld y sesiynau ar y dudalen hon yn ôl hyd, neu fel taith gysylltiedig o 1 – 8.<br /><br />',
        'para2': 'Cliciwch "Gweld fel taith" i weld y sesiynau yn y drefn y maen nhw’n ymddangos yn y canllaw i Athrawon.',
        'buttonTop': 'Gweld fel taith',
        'buttonBtm': 'Lawrlwytho’r Canllaw Athro'
    },
    'shortSessions': {
        'title': 'Sesiynau byr',
        'para': 'Mae’r gweithgareddau byrrach hyn yn wych ar gyfer cyflwyno cysyniadau i ddosbarth mewn rhannau hyblyg, bach eu maint.'
    },
    'fullSessions': {
        'title': 'Sesiynau llawn',
        'para': 'Mae’r rhain yn weithgareddau llawn sy’n canolbwyntio ar wahanol bynciau a dysgu trwy chwarae.'
    },
    'enterprise': {
        'title': 'Prosiect menter',
        'para': 'Mae’r prosiectau bach hyn yn weithgareddau grŵp y gallwch eu cwblhau gyda’ch dosbarth dros ychydig o wersi a gallent hefyd fod yn rhan o brosiectau mwy, a allai gymryd tymor cyfan.'
    }
};

const ResourcesTemplate = ({ data, pageContext }) => {
    let langArray = englishArray;
    if(pageContext.resource.lang === 'cy') {
        langArray = welshArray;
    }
    return (
        <Layout lang={pageContext.resource.transitionLang} langURL={pageContext.resource.transitionURL}>
            <Seo title={pageContext.resource.metaTitle} description={pageContext.resource.metaDescription}
                 lang={pageContext.resource.lang === 'en' ? `${pageContext.resource.lang}-GB` : pageContext.resource.transitionLang}/>
            <section className={'resources-banner resources-banner-' + pageContext.resource.ageGroup} role={'banner'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row className={'d-flex'}>
                        <Col xs={12} className={'resources-banner--icon'}>
                            <img src={data.badge.publicURL} alt={'Age Badge'} className={'my-5'}/>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={'resources-cta mt-5 pt-4'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} md={10} className={'offset-md-1'}>
                            <div className={'resources-cta--banner p-4 p-xl-5'}>
                                <Row>
                                    <Col xs={12} lg={7} className={'py-lg-3 mb-4 mb-lg-0 resources-cta--banner__text'}>
                                        <p><strong dangerouslySetInnerHTML={{__html: langArray.banner.para1}} /></p>
                                        <p className={'mb-0'}>{langArray.banner.para2}</p>
                                    </Col>
                                    <Col xs={12} lg={5} className={'my-auto'}>
                                        <p className="mt-0 mb-3">
                                            <Link to={pageContext.resource.handbookLink} className={'resources-cta--button'}>
                                                {langArray.banner.buttonTop}
                                            </Link>
                                        </p>
                                        <p className="my-0">
                                            <a href={data.guide.publicURL} className={'resources-cta--button'} target={'_blank'} rel={'noreferrer'}>
                                                {langArray.banner.buttonBtm}
                                            </a>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <main className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} md={10} className={'offset-md-1'}>
                            <h2 className={'content--h2'}>{langArray.shortSessions.title}</h2>
                            <p>{langArray.shortSessions.para}</p>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Slider ageGroup={pageContext.resource.ageGroup} lessonSize={'short'} language={pageContext.resource.lang} />
            <Container bsPrefix={'wrap'} fluid={false}>
                <Row>
                    <Col xs={12} md={10} className={'offset-md-1'}>
                        <hr/>
                    </Col>
                </Row>
            </Container>
            <main className="content py-5" role="main">
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} md={10} className={'offset-md-1'}>
                            <h2 className={'content--h2'}>{langArray.fullSessions.title}</h2>
                            <p>{langArray.fullSessions.para}</p>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Slider ageGroup={pageContext.resource.ageGroup} lessonSize={'long'} language={pageContext.resource.lang} />
            <Container bsPrefix={'wrap'} fluid={false} className={'py-5'}>
                <Row>
                    <Col xs={10} className={'offset-1'}>
                        <hr/>
                    </Col>
                </Row>
            </Container>
            <main className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} md={10} className={'offset-md-1'}>
                            <h2 className={'content--h2'}>{langArray.enterprise.title}</h2>
                            <p>{langArray.enterprise.para}</p>
                        </Col>
                    </Row>
                </Container>
            </main>
            <EnterpriseActivity
                ageGroup={data.enterprise.ageGroup}
                ageIcon={data.enterprise.icon}
                activityType={data.enterprise.resourceType}
                activityNumber={data.enterprise.activityNumber}
                activityTitle={data.enterprise.name}
                activityDescription={data.enterprise.short}
                activityURL={data.enterprise.url}
                activityTime={data.enterprise.time}
                activityResources={data.enterprise.level}
                activitySpace={data.enterprise.space}
                activityLanguage={pageContext.resource.lang}
            />
            <Largepopup lang={pageContext.resource.lang}/>
        </Layout>
    )
}

export default ResourcesTemplate