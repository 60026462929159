import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";

import Activity from "./activity";
import $ from "jquery/dist/jquery";

function Slider ({ ageGroup, lessonSize, language }) {
    const arrows = useStaticQuery(graphql`query ArrowQuery {
      left: file(relativePath: {eq: "SVG/left-slider-button.svg"}) {
        id
        publicURL
      }
      right: file(relativePath: {eq: "SVG/right-slider-button.svg"}) {
        id
        publicURL
      }
      activities: allLessonsJson {
        edges {
          node {
            id
            name
            icon
            url
            ageGroup
            resourcesURL
            activityNumber
            activityPlan
            description
            lang
            level
            resourceType
            short
            space
            time
          }
        }
      }
    }`);

    if (typeof window !== 'undefined') {
        setTimeout(function () {
            if ($(window).width() <= 991.98) {
                carouselWrap('activities--container--short', 1);
                carouselWrap('activities--container--long', 1);
            } else {
                carouselWrap('activities--container--short', 2);
                carouselWrap('activities--container--long', 2);
            }
        }, 1000);

        $(window).on('resize', function () {
            let win = $(this);
            if (win.width() <= 991.98) {
                removeCarousel('activities--container--short', 2);
                removeCarousel('activities--container--long', 2);
                carouselWrap('activities--container--short', 1);
                carouselWrap('activities--container--long', 1);
            }
            if (win.width() >= 992) {
                removeCarousel('activities--container--short', 1);
                removeCarousel('activities--container--long', 1);
                carouselWrap('activities--container--short', 2);
                carouselWrap('activities--container--long', 2);
            }
        });

        setTimeout(function () {
            $('#' + lessonSize + 'Activities').carousel();
        }, 1000);
    }

    function removeCarousel(classname, wrapNumber) {
        let divs = $('.' + classname);
        for (let i = 0; i < divs.length; i += wrapNumber) {
            if(divs.slice(i, i + wrapNumber).parent().is('.row')) {
                divs.slice(i, i + wrapNumber).unwrap();
            }
            if(divs.slice(i, i + wrapNumber).parent().is('.carousel-item')) {
                divs.slice(i, i + wrapNumber).unwrap();
            }
        }
    }

    function carouselWrap(classname, wrapNumber) {
        let divs = $('.' + classname);
        for (let i = 0; i < divs.length; i += wrapNumber) {
            let active;
            if(i === 0) {
                active = " active";
            } else {
                active = "";
            }
            let checkArray = [];
            $(divs).each(function(idx, val) {
                if($(this).parent().hasClass('row')) {
                    checkArray[idx] = true;
                } else {
                    checkArray[idx] = false;
                }
            });
            if(checkArray[i] === false && checkArray[i] !== 'undefined') {
                divs.slice(i, i + wrapNumber).wrapAll('<div class="carousel-item' + active + '"><div class="row"></div></div>');
            }
        }
    }

    return (
        <section className={'activities pb-md-5 pb-3'}>
            <Container bsPrefix={'wrap'} fluid={false}>
                <Row className={'carousel--row'}>
                    <Col md={1} xs={6} className={'carousel--left pt-3 pt-md-0'}>
                        <a className={'carousel-control-prev'} href={'#' + lessonSize +'Activities'} data-slide={'prev'}>
                            <img src={arrows.left.publicURL} alt={'Slide Left'} className={'activities--slide-arrow'} />
                        </a>
                    </Col>
                    <Col xs={12} md={10} className={'order-2 order-md-1 offset-md-1 offset-lg-0'}>
                        <div id={lessonSize + 'Activities'} className={'carousel slide'}>
                            <div className={'carousel-inner activities--carousel mx-auto'} role={'listbox'}>
                                {arrows.activities.edges.filter(edge => edge.node.ageGroup === ageGroup && edge.node.resourceType === lessonSize && edge.node.lang === language).map((val, idx) => (
                                    <Activity
                                        key={idx}
                                        ageGroup={val.node.ageGroup}
                                        ageIcon={val.node.icon}
                                        activityType={val.node.resourceType}
                                        activityNumber={val.node.activityNumber}
                                        activityTitle={val.node.name}
                                        activityDescription={val.node.short}
                                        activityURL={val.node.url}
                                        activityTime={val.node.time}
                                        activityResources={val.node.level}
                                        activitySpace={val.node.space}
                                        activityLang={val.node.lang}
                                    />
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col md={1} xs={6} className={'carousel--right pt-3 pt-md-0'}>
                        <a className={'carousel-control-next'} href={'#' + lessonSize +'Activities'} data-slide={'next'}>
                            <img src={arrows.right.publicURL} alt={'Slide Right'} className={'activities--slide-arrow'} />
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Slider;